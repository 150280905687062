<script setup>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import MaNewStaffSummary from "@/pages/MA/humanResources/manager/MaNewStaffSummary.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();

store.dispatch("MAnewHire/fetchSingleHiringRequest", {
  hiring_request: route.params.id,
});
const clearState = () => {
  store.commit("MAnewHire/setNewHiringRequest", {});
  router.push({ name: "ma-new-hire" });
};
</script>
<template>
  <MaNewStaffSummary
    :buttonText="'Close'"
    @submitAction="clearState"
    :submitAction="true"
  />
</template>
